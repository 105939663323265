export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "ADD_ITEM_LOCATION_SUCCESS":
    case "ADD_ITEM_LOCATION_ERROR":
      return {
        ...state,
        AddItemLocationResponse: action.updatePayload,
      };
    case "CHECK_LOCATION_SUCCESS":
    case "CHECK_LOCATION_ERROR":
      return {
        ...state,
        CheckLocationResponse: action.updatePayload,
      };
    case "GET_ALL_SCANED_ITEMS_SUCCESS":
    case "GET_ALL_SCANED_ITEMS_ERROR":
      return {
        ...state,
        GetAllScannedItemsResponse: action.updatePayload,
      };
    case "FAKE_ACTION_ITEM_LOCATION":
      return [];
    default:
      return state;
  }
};
