import { Image, Input, Pagination, Table, Tag, Tooltip, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading";

export default function ({ GetAmazonListing, fakeActionAmazonListing }) {
  const GetAmazonListingRes = useSelector(
    (state) => state.AmazonListing.GetAmazonListingResponse || {}
  );

  const [current, setCurrent] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  //   useEffect(() => {
  //     message.destroy();
  //     setLoading(true);
  //     GetAmazonListing({
  //       search: searchText,
  //       page: current,
  //       perPage: rowsPerPage,
  //     });
  //   }, []);

  useEffect(() => {
    if (GetAmazonListingRes?.status === true) {
      setList(GetAmazonListingRes?.data?.records || []);
      setLoading(false);
      let pagination = GetAmazonListingRes?.data?.pagination;
      setTotalRecord(pagination?.totalCount);
      fakeActionAmazonListing();
    } else if (GetAmazonListingRes?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionAmazonListing();
    }
  }, [GetAmazonListingRes]);

  const onShowSizeChange = (current, pageSize) => {
    setCurrent(current);
    setRowsPerPage(pageSize);
    setLoading(true);
    GetAmazonListing({
      search: searchText,
      page: current,
      perPage: pageSize,
    });
  };

  const onPaginationChange = (page, pageSize) => {
    if (current !== page) {
      setCurrent(page);
      setLoading(true);
      GetAmazonListing({
        search: searchText,
        page: page,
        perPage: pageSize,
      });
    }
  };

  const columns = [
    {
      title: "mSKU",
      dataIndex: "mSKU",
      key: "mSKU",
    },
    {
      title: "Listing/SKU/Status",
      dataIndex: "listingSKUStatus",
      key: "listingSKUStatus",
    },
    {
      title: "Selling Status",
      dataIndex: "sellingStatus",
      key: "sellingStatus",
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "fnSKU",
      dataIndex: "fnSKU",
      key: "fnSKU",
    },
    {
      title: "SellerASIN Status",
      dataIndex: "sellerASINStatus",
      key: "sellerASINStatus",
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
    },
  ];

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row g-5 g-xl-5 mb-5">
              <div className="col-md-12 d-md-flex justify-content-sm-between">
                <div className="d-md-flex justify-content-start">
                  <div className="fs-5 fw-bold text-gray-700 d-flex align-items-center ps-3 pt-5">
                    Showing{" "}
                    {totalRecord === 0
                      ? 0
                      : current === 1
                      ? 1
                      : (current - 1) * rowsPerPage + 1}{" "}
                    to{" "}
                    {totalRecord <= rowsPerPage
                      ? totalRecord
                      : totalRecord < rowsPerPage * current
                      ? totalRecord
                      : rowsPerPage * current}{" "}
                    of {totalRecord} entries
                  </div>
                </div>
              </div>
            </div>
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Amazon Listings
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <div className="position-relative ms-3">
                        <i
                          className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"
                          style={{ zIndex: 9 }}
                        />
                        <Input
                          placeholder="Search Records"
                          size="large"
                          className="w-300px fs-6 text-dark ps-12 all-search bg-white"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                            if (e.target.value.length === 0) {
                              setLoading(true);
                              GetAmazonListing({
                                search: "",
                                page: current,
                                perPage: rowsPerPage,
                              });
                            }
                          }}
                          value={searchText}
                          onPressEnter={(e) => {
                            setLoading(true);
                            GetAmazonListing({
                              search: e.target.value,
                              page: current,
                              perPage: rowsPerPage,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive tbl-sales">
                      <table
                        className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 border-top-d"
                        id
                      >
                        <thead>
                          <tr className="fw-bolder text-dark bg-light-info">
                            <th className="min-w-75px">mSKU</th>
                            <th className="min-w-75px">Listing/SKU/Status</th>
                            <th className="min-w-75px">Selling Status</th>
                            <th className="min-w-75px">ASIN</th>
                            <th className="min-w-75px">fnSKU</th>
                            <th className="min-w-75px">SellerASIN Status</th>
                            <th className="min-w-75px">Condition</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-700 fw-bold fs-7">
                          {loading ? (
                            <Loading col={9} />
                          ) : (
                            <>
                              {list.length > 0 ? (
                                <>
                                  <Table columns={columns} dataSource={list} />;
                                </>
                              ) : (
                                <tr>
                                  <td colSpan={9}>
                                    <div className="py-7 text-center">
                                      <div className="ant-empty-image">
                                        <svg
                                          width={64}
                                          height={41}
                                          viewBox="0 0 64 41"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fillRule="evenodd"
                                          >
                                            <ellipse
                                              fill="#f5f5f5"
                                              cx={32}
                                              cy={33}
                                              rx={32}
                                              ry={7}
                                            />
                                            <g
                                              fillRule="nonzero"
                                              stroke="#d9d9d9"
                                            >
                                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                              <path
                                                d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                fill="#fafafa"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                      </div>
                                      <div>No data found...</div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {totalRecord > 10 && (
                      <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                        <div />
                        <Pagination
                          showSizeChanger
                          onShowSizeChange={onShowSizeChange}
                          current={current}
                          total={totalRecord}
                          onChange={onPaginationChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
