import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AmazonListings from "../../modules/pages/amazon-listings/amazon-listings";
import { fakeActionAmazonListing } from "../../redux/modules/amazon-listing/amazon-listing.action";

const mapStateToProps = (state) => ({
  // GetAmazonListingResponse: state.AmazonListing.GetAmazonListingResponse
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // GetAmazonListing,
      fakeActionAmazonListing,
    },
    dispatch
  );

const AmazonListings_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(AmazonListings);

export default AmazonListings_;
