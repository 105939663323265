import React from "react";
import ContentLoader from "react-content-loader";

export default function ({ col }) {
  return (
    <>
      {[...Array(10).keys()]?.map((_, i) => (
        <tr key={i}>
          {[...Array(col).keys()]?.map((_, i) => (
            <td>
              <ContentLoader
                speed={1}
                width={150}
                height={50}
                viewBox="0 0 150 30"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="150" height="50" />
              </ContentLoader>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
