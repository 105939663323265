import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../core/asyncComponent";
import { adminRoutersList, userRoutersList, staffRoutersList } from "./lib";

function PageRouter({ authStatus }) {
  let routeList =
    authStatus === "admin"
      ? adminRoutersList
      : authStatus === "staff"
      ? adminRoutersList
      : userRoutersList;

  return (
    <Suspense
      fallback={
        <div className="overlayLoading">
          <div className="loader"></div>
        </div>
      }
    >
      <Switch>
        {routeList?.map((d, i) => {
          return (
            d && (
              <Route
                key={i}
                path={d?.path}
                component={asyncComponent(d?.name)}
                exact
              />
            )
          );
        })}
        <Route
          path="*"
          render={() => (
            <div
              className
              style={{
                width: "100%",
                position: "sticky",
                left: "0px",
                overflow: "hidden",
              }}
            >
              <div className="sc-dmqHEX ezYVF" style={{ height: "300px" }}>
                <div>
                  <div
                    style={{
                      transform: "translate(-50%, -50%)",
                      top: "50%",
                      left: "50%",
                      position: "absolute",
                      textAlign: "center",
                    }}
                  >
                    <span>
                      <img
                        src="/assets/media/no-data-found.png"
                        style={{ width: "250px" }}
                        alt=""
                      />
                    </span>
                    <div
                      style={{
                        fontSize: "18px",
                        color: "rgb(54, 58, 62)",
                        opacity: 1,
                        paddingTop: "3px",
                        fontWeight: "bold",
                        letterSpacing: "-0.36px",
                      }}
                    >
                      Page Not Found...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </Switch>
    </Suspense>
  );
}

export default PageRouter;
