import axiosCall from "../../configurations/network-services/axiosCall";

// export const GetAmazonListing = (payload) => {
//   const path = `login`;
//   const responseType = "LOGIN";
//   const body = payload;
//   return axiosCall("post", path, responseType, body);
// };

export const fakeActionAmazonListing = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AMAZON_LISTING" });
};
