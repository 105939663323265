import { ConvertParams } from "../../../config";
import axiosCall from "../../configurations/network-services/axiosCall";

export const GetItemListAction = (data) => {
  const path = `inventory-items/get-items${ConvertParams(data)}`;
  const responseType = "GET_ITEM_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetExpectedItemDetailsAction = (id) => {
  const path = `inventory-items/expected-item-details/${id}`;
  const responseType = "GET_EXPECTED_ITEM_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetReceiveItemDropdownDataAction = (data) => {
  const path = `inventory-items/receive-item-dropdown-data`;
  const responseType = "GET_RECEIVE_ITEM_DROPDOWN_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetRelatedAmazonListAction = (client_id, asin) => {
  const path = `inventory-items/related-amazon-listings/${client_id}/${asin}`;
  const responseType = "GET_RELATED_AMAZON_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ValidateReceivedItemAction = (id, data) => {
  const path = `inventory-items/${id}/validate-received-item`;
  const responseType = "VALIDATE_RECEIVED_ITEMS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetFindAsinFromBarcodeAction = (client_id, data) => {
  const path = `inventory-items/search-item-asin/${client_id}/${data}`;
  const responseType = "FIND_ASIN_FROM_BARCODE";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetFindAdvanceAsinSearchAction = (client_id, data) => {
  const path = `inventory-items/advance-search-items/${client_id}?search_text=${data}`;
  const responseType = "FIND_ADVANCE_ASIN_SEARCH";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const SaveInventoryItemsWithoutOrder = (data) => {
  const path = `inventory-items/receive-item-without-order`;
  const responseType = "SAVE_INVENTORY_ITEMS_WITHOUT_ORDER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSingleReceivedItemAction = (id) => {
  const path = `inventory-items/${id}`;
  const responseType = "GET_SINGLE_RECEIVED_ITEM";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionInventoryItems = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_EXPECTED_ITEMS" });
};
