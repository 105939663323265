import React from "react";
import ExceptionHome_ from "../../containers/exception-homes";
import AmazonListings_ from "../../containers/amazon-listing";

// All Routes
const Dashboard_ = React.lazy(() =>
  import("../../containers/system-dashboard")
);
const ScanDeliveries_ = React.lazy(() =>
  import("../../containers/scan-deliveries")
);
const Client_ = React.lazy(() => import("../../containers/client"));
const ClientAddress_ = React.lazy(() =>
  import("../../containers/client/address")
);
const AddClientDetails_ = React.lazy(() =>
  import("../../containers/client/AddClientDetails")
);
const AddClientPrepCenter_ = React.lazy(() =>
  import("../../containers/client/AddClientPrepCenter")
);
const InventoryOrder_ = React.lazy(() =>
  import("../../containers/inventory-order")
);
const InventoryOrderView_ = React.lazy(() =>
  import("../../containers/inventory-order/view")
);
const Items_ = React.lazy(() => import("../../containers/inventory-items"));
const ItemsView_ = React.lazy(() =>
  import("../../containers/inventory-items/itemsView")
);
const SendToFBA_ = React.lazy(() =>
  import("../../containers/inventory-items/sendToFba")
);
const ItemStatusView_ = React.lazy(() =>
  import("../../containers/inventory-items/ItemViewStatus")
);
const ItemStatusAdd_ = React.lazy(() =>
  import("../../containers/inventory-items/itemStatusAdd")
);
// const ClientItemStatusView_ = React.lazy(() =>
//     import("../../modules/pages/inventory-items/ClientItemStatusView")
// );
const PrepStaff_ = React.lazy(() => import("../../containers/prep-staff"));
const ReceiveItems_ = React.lazy(() =>
  import("../../containers/receive-items")
);
const Shipping_ = React.lazy(() =>
  import("../../modules/pages/operation/shipping")
);
const PrepBatch_ = React.lazy(() =>
  import("../../containers/operation/prep-batch")
);
const AddPrepBatch_ = React.lazy(() =>
  import("../../containers/operation/prep-batch/AddPrepBatch")
);
const ViewPrepBatch_ = React.lazy(() =>
  import("../../modules/pages/operation/prep-batch/view")
);
const AddPrepBatchItems_ = React.lazy(() =>
  import("../../containers/operation/prep-batch/AddItemsToBatch")
);
// const AddPrepBatchItemsShipment_ = React.lazy(() =>
//   import("../../modules/pages/operation/prep-batch/add/ShipmentBatch")
// );
const Profile_ = React.lazy(() => import("../../containers/profile"));
const Billing_ = React.lazy(() =>
  import("../../modules/pages/settings/billing")
);
const MarketplaceCredentials_ = React.lazy(() =>
  import("../../modules/pages/settings/marketplace-credentials")
);
const Help_ = React.lazy(() => import("../../modules/pages/settings/help"));
const CentralLog_ = React.lazy(() =>
  import("../../containers/application-logs/central-log")
);
const SystemErrorLog_ = React.lazy(() =>
  import("../../modules/pages/application-logs/system-error-log")
);
const PreReceivingListings_ = React.lazy(() =>
  import("../../containers/admin-actions/pre-receiving-listings")
);
const CronManager_ = React.lazy(() =>
  import("../../containers/admin-actions/cron-manager")
);
const AddCronManager_ = React.lazy(() =>
  import("../../containers/admin-actions/cron-manager/AddCronManager")
);
const AddItemToLocation_ = React.lazy(() =>
  import("../../containers/add-item-to-location")
);
const Location_ = React.lazy(() => import("../../containers/location"));
const AddLocation_ = React.lazy(() =>
  import("../../containers/location/AddLocation")
);
const ScanItem_ = React.lazy(() =>
  import("../../modules/pages/mobile-helper/scan-item")
);
const ScanList_ = React.lazy(() =>
  import("../../modules/pages/mobile-helper/scan-list")
);
const Sources_ = React.lazy(() => import("../../containers/sources"));
const AddSource_ = React.lazy(() =>
  import("../../containers/sources/AddSource")
);
const FbaPackingSummaryReport_ = React.lazy(() =>
  import("../../modules/pages/fba-packing-summary")
);
const PrepCenter_ = React.lazy(() =>
  import("../../containers/operation/prep-center")
);
const AddPrepCenter_ = React.lazy(() =>
  import("../../containers/operation/prep-center/AddPrepCenter")
);
const ShellListing_ = React.lazy(() =>
  import("../../containers/shell-listing")
);
const GetPictureInfo_ = React.lazy(() =>
  import("../../containers/inventory-items/getPitcureInfo/getPictureInfo")
);
const SendToAmazonFba_ = React.lazy(() =>
  import("../../containers/inventory-items/sub-pages/send-to-amzon-fba")
);

export const adminRoutersList = [
  {
    path: "/",
    key: "system-dashboard",
    name: Dashboard_,
  },
  {
    path: "/system-dashboard",
    key: "system-dashboard",
    name: Dashboard_,
  },
  {
    path: "/shipments",
    key: "dashboard-shipments",
    name: Shipping_,
  },
  {
    path: "/inventory-order",
    key: "inventory-order",
    name: InventoryOrder_,
  },
  {
    path: "/inventory-order/view",
    key: "inventory-view",
    name: InventoryOrderView_,
  },
  {
    path: "/inventory-items",
    key: "inventory-items",
    name: Items_,
  },
  {
    path: "/inventory-items/view",
    key: "items-view",
    name: ItemsView_,
  },
  {
    path: "/inventory-items/status/add",
    key: "items-status-add",
    name: ItemStatusAdd_,
  },
  {
    path: "/receiving-picture-info",
    key: "receiving-picture-info",
    name: GetPictureInfo_,
  },
  {
    path: "/prep-batches",
    key: "operation-prep-batch",
    name: PrepBatch_,
  },
  {
    path: "/prep-batches/add",
    key: "operation-prep-batch-add",
    name: AddPrepBatch_,
  },
  {
    path: "/prep-batches/edit",
    key: "operation-prep-batch-edit",
    name: AddPrepBatch_,
  },
  {
    path: "/prep-batches/view",
    key: "operation-prep-batch-view",
    name: ViewPrepBatch_,
  },
  {
    path: "/prep-batches/add-batch-items",
    key: "operation-prep-batch-add-items",
    name: AddPrepBatchItems_,
  },
  {
    path: "/sources",
    key: "sources",
    name: Sources_,
  },
  {
    path: "/sources/add",
    key: "sources-add",
    name: AddSource_,
  },
  {
    path: "/sources/edit",
    key: "sources-edit",
    name: AddSource_,
  },
  {
    path: "/sources/view",
    key: "sources-view",
    name: AddSource_,
  },
  {
    path: "/client-account",
    key: "client-account",
    name: Client_,
  },
  {
    path: "/client-account/client-address",
    key: "entities-client-address",
    name: ClientAddress_,
  },
  {
    path: "/client-account/details",
    key: "add-new-user",
    name: AddClientDetails_,
  },
  {
    path: "/client-account/add-prep-center",
    key: "add-prep-center",
    name: AddClientPrepCenter_,
  },
  {
    path: "/client-account/edit-prep-center",
    key: "edit-prep-center",
    name: AddClientPrepCenter_,
  },
  {
    path: "/client-account/view-prep-center",
    key: "view-prep-center",
    name: AddClientPrepCenter_,
  },
  {
    path: "/prep-staff",
    key: "prep-staff",
    name: PrepStaff_,
  },
  {
    path: "/locations",
    key: "locations",
    name: Location_,
  },
  {
    path: "/locations/add",
    key: "add-locations",
    name: AddLocation_,
  },
  {
    path: "/locations/edit",
    key: "edit-locations",
    name: AddLocation_,
  },
  {
    path: "/delivery-scanning",
    key: "delivery-scanning",
    name: ScanDeliveries_,
  },
  {
    path: "/receiving-items",
    key: "receiving-items",
    name: ReceiveItems_,
  },
  {
    path: "/add-item-to-location",
    key: "add-item-to-location",
    name: AddItemToLocation_,
  },
  {
    path: "/cron-manager",
    key: "admin-cron-manager",
    name: CronManager_,
  },
  {
    path: "/cron-manager/add",
    key: "admin-cron-manager-add",
    name: AddCronManager_,
  },
  {
    path: "/cron-manager/edit",
    key: "admin-cron-manager-edit",
    name: AddCronManager_,
  },
  {
    path: "/pre-receiving-report",
    key: "admin-pre-receiving-listings",
    name: PreReceivingListings_,
  },
  {
    path: "/shell-listing-panel",
    key: "admin-shell-listing-panel",
    name: ShellListing_,
  },
  {
    path: "/logs",
    key: "logs",
    name: CentralLog_,
  },
  {
    path: "/help",
    key: "help",
    name: Help_,
  },
  {
    path: "/profile",
    key: "profile",
    name: Profile_,
  },
  {
    path: "/inventory-items/send-to-amazon-fba",
    key: "send-to-amazon-fba",
    name: SendToAmazonFba_,
  },
  {
    path: "/exceptions-home",
    key: "exception-home",
    name: ExceptionHome_,
  },
  {
    path: "/amazon-listings",
    key: "amazon-listings",
    name: AmazonListings_,
  },
  {
    path: "/scan-item",
    key: "scan-item",
    name: ScanItem_,
  },
  {
    path: "/scan-list",
    key: "scan-list",
    name: ScanList_,
  },
];
// export const adminRoutersList_ = [
//   {
//     path: "/",
//     key: "dashboard",
//     name: Dashboard_,
//   },
//   {
//     path: "/dashboard",
//     key: "dashboard",
//     name: Dashboard_,
//   },
//   {
//     path: "/scan-deliveries",
//     key: "scan-deliveries",
//     name: ScanDeliveries_,
//   },
//   {
//     path: "/entities/client",
//     key: "entities-client",
//     name: Client_,
//   },
//   {
//     path: "/callbackspapi",
//     key: "callbackspapi",
//     name: Client_,
//   },
//   {
//     path: "/entities/prep-staff",
//     key: "prep-staff",
//     name: PrepStaff_,
//   },
//   {
//     path: "/entities/client/client-address/:id",
//     key: "entities-client-address",
//     name: ClientAddress_,
//   },
//   {
//     path: "/entities/client/details/:id",
//     key: "add-new-user",
//     name: AddClientDetails_,
//   },
//   {
//     path: "/entities/client/add-prep-center/:id",
//     key: "add-prep-center",
//     name: AddClientPrepCenter_,
//   },
//   {
//     path: "/entities/client/edit-prep-center/:id",
//     key: "edit-prep-center",
//     name: AddClientPrepCenter_,
//   },
//   {
//     path: "/entities/client/view-prep-center/:id",
//     key: "view-prep-center",
//     name: AddClientPrepCenter_,
//   },
//   {
//     path: "/inventory-order",
//     key: "inventory-order",
//     name: InventoryOrder_,
//   },
//   {
//     path: "/inventory-order/view",
//     key: "inventory-view",
//     name: InventoryOrderView_,
//   },
//   {
//     path: "/inventory-items",
//     key: "inventory-items",
//     name: Items_,
//   },
//   {
//     path: "/inventory-items/view",
//     key: "items-view",
//     name: ItemsView_,
//   },
//   {
//     path: "/inventory-items/sent-to-fba/:id",
//     key: "sent-to-fba",
//     name: SendToFBA_,
//   },
//   // {
//   //   path: "/inventory-items/status/view/:id",
//   //   key: "items-status-view",
//   //   name: ItemStatusView_,
//   // },
//   {
//     path: "/inventory-items/status/add",
//     key: "items-status-add",
//     name: ItemStatusAdd_,
//   },
//   {
//     path: "/receive-items",
//     key: "receive-items",
//     name: ReceiveItems_,
//   },
//   {
//     path: "/operation/shipping",
//     key: "operation-shipping",
//     name: Shipping_,
//   },
//   {
//     path: "/operation/prep-batch",
//     key: "operation-prep-batch",
//     name: PrepBatch_,
//   },
//   {
//     path: "/operation/prep-batch/add",
//     key: "operation-prep-batch-add",
//     name: AddPrepBatch_,
//   },
//   {
//     path: "/operation/prep-batch/edit/:id",
//     key: "operation-prep-batch-edit",
//     name: AddPrepBatch_,
//   },
//   {
//     path: "/operation/prep-batch/view/:id",
//     key: "operation-prep-batch-view",
//     name: ViewPrepBatch_,
//   },
//   {
//     path: "/operation/prep-batch/add-batch-items/:id",
//     key: "operation-prep-batch-add-items",
//     name: AddPrepBatchItems_,
//   },
//   // {
//   //   path: "/operation/prep-batch/add-batch-items-shipment",
//   //   key: "operation-prep-batch-add-items-shipment",
//   //   name: AddPrepBatchItemsShipment_,
//   // },
//   {
//     path: "/settings/profile",
//     key: "settings-profile",
//     name: Profile_,
//   },
//   {
//     path: "/settings/billing",
//     key: "settings-billing",
//     name: Billing_,
//   },
//   {
//     path: "/settings/marketplace-credentials",
//     key: "settings-marketplace-credentials",
//     name: MarketplaceCredentials_,
//   },
//   {
//     path: "/settings/help",
//     key: "settings-help",
//     name: Help_,
//   },
//   {
//     path: "/application-logs/central-log",
//     key: "application-central",
//     name: CentralLog_,
//   },
//   {
//     path: "/application-logs/system-error-log",
//     key: "application-system",
//     name: SystemErrorLog_,
//   },
//   {
//     path: "/admin-actions/pre-receiving-listings",
//     key: "admin-actions-pre-receiving-listings",
//     name: PreReceivingListings_,
//   },
//   {
//     path: "/admin-actions/cron-manager",
//     key: "admin-actions-cron-manager",
//     name: CronManager_,
//   },
//   {
//     path: "/admin-actions/cron-manager/add",
//     key: "admin-actions-cron-manager-add",
//     name: AddCronManager_,
//   },
//   {
//     path: "/admin-actions/cron-manager/edit/:id",
//     key: "admin-actions-cron-manager-edit",
//     name: AddCronManager_,
//   },
//   {
//     path: "/add-item-to-location",
//     key: "add-item-to-location",
//     name: AddItemToLocation_,
//   },
//   {
//     path: "/location",
//     key: "location",
//     name: Location_,
//   },
//   {
//     path: "/location/add",
//     key: "add-location",
//     name: AddLocation_,
//   },
//   {
//     path: "/location/edit/:id",
//     key: "edit-location",
//     name: AddLocation_,
//   },
//   {
//     path: "/scan-item",
//     key: "scan-item",
//     name: ScanItem_,
//   },
//   {
//     path: "/scan-list/:scan_id",
//     key: "scan-list",
//     name: ScanList_,
//   },
//   {
//     path: "/sources",
//     key: "sources",
//     name: Sources_,
//   },
//   {
//     path: "/sources/add",
//     key: "sources-add",
//     name: AddSource_,
//   },
//   {
//     path: "/sources/view/:id",
//     key: "sources-view",
//     name: AddSource_,
//   },
//   {
//     path: "/sources/edit/:id",
//     key: "sources-edit",
//     name: AddSource_,
//   },
//   {
//     path: "/fba-packing-summary-report",
//     key: "fba-packing-summary-report",
//     name: FbaPackingSummaryReport_,
//   },
//   {
//     path: "/operation/prep-center",
//     key: "operation-prep-center",
//     name: PrepCenter_,
//   },
//   {
//     path: "/operation/prep-center/add",
//     key: "operation-prep-center-add",
//     name: AddPrepCenter_,
//   },
//   {
//     path: "/operation/prep-center/edit/:id",
//     key: "operation-prep-center-edit",
//     name: AddPrepCenter_,
//   },
//   {
//     path: "/shell-listing",
//     key: "shell-listing",
//     name: ShellListing_,
//   },
// ];

export const userRoutersList = [
  {
    path: "/",
    key: "system-dashboard",
    name: Dashboard_,
  },
  {
    path: "/system-dashboard",
    key: "system-dashboard",
    name: Dashboard_,
  },
  {
    path: "/inventory-order",
    key: "inventory-order",
    name: InventoryOrder_,
  },
  {
    path: "/inventory-order/view",
    key: "inventory-view",
    name: InventoryOrderView_,
  },
  {
    path: "/inventory-items",
    key: "inventory-items",
    name: Items_,
  },
  {
    path: "/inventory-items/view",
    key: "items-view",
    name: ItemsView_,
  },
  {
    path: "/inventory-items/status/add",
    key: "items-status-add",
    name: ItemStatusAdd_,
  },
  {
    path: "/sources",
    key: "sources",
    name: Sources_,
  },
  {
    path: "/sources/add",
    key: "sources-add",
    name: AddSource_,
  },
  {
    path: "/sources/edit",
    key: "sources-edit",
    name: AddSource_,
  },
  {
    path: "/sources/view",
    key: "sources-view",
    name: AddSource_,
  },
  {
    path: "/amazon-listings",
    key: "amazon-listings",
    name: AmazonListings_,
  },
  {
    path: "/prep-batches",
    key: "operation-prep-batch",
    name: PrepBatch_,
  },
  {
    path: "/prep-batches/add",
    key: "operation-prep-batch-add",
    name: AddPrepBatch_,
  },
  {
    path: "/prep-batches/edit",
    key: "operation-prep-batch-edit",
    name: AddPrepBatch_,
  },
  {
    path: "/prep-batches/view",
    key: "operation-prep-batch-view",
    name: ViewPrepBatch_,
  },
  {
    path: "/prep-batches/add-batch-items",
    key: "operation-prep-batch-add-items",
    name: AddPrepBatchItems_,
  },
  {
    path: "/help",
    key: "help",
    name: Help_,
  },
];
export const staffRoutersList = [];

// export const userRoutersList = [
//   {
//     path: "/",
//     key: "dashboard",
//     name: Dashboard_,
//   },
//   {
//     path: "/dashboard",
//     key: "dashboard",
//     name: Dashboard_,
//   },
//   {
//     path: "/inventory-order",
//     key: "inventory-order",
//     name: InventoryOrder_,
//   },
//   {
//     path: "/inventory-order/view",
//     key: "inventory-view",
//     name: InventoryOrderView_,
//   },
//   {
//     path: "/inventory-items",
//     key: "items",
//     name: Items_,
//   },
//   {
//     path: "/inventory-items/view",
//     key: "items-view",
//     name: ItemsView_,
//   },
//   {
//     path: "/inventory-items/sent-to-fba/:id",
//     key: "sent-to-fba",
//     name: SendToFBA_,
//   },
//   {
//     path: "/prep-batches",
//     key: "prep-batches",
//     name: PrepBatch_,
//   },
//   {
//     path: "/client-account/",
//     key: "client-account",
//     name: Client_,
//   },
//   {
//     path: "/my-settings",
//     key: "my-settings",
//     name: Profile_,
//   },
//   {
//     path: "/help",
//     key: "help",
//     name: Help_,
//   },
//   {
//     path: "/settings/profile",
//     key: "settings-profile",
//     name: Profile_,
//   },
//   // {
//   //   path: "/settings/billing",
//   //   key: "settings-billing",
//   //   name: Billing_,
//   // },
//   // {
//   //   path: "/settings/marketplace-credentials",
//   //   key: "settings-marketplace-credentials",
//   //   name: MarketplaceCredentials_,
//   // },
//   // {
//   //   path: "/settings/help",
//   //   key: "settings-help",
//   //   name: Help_,
//   // },
//   {
//     path: "/application-logs/central-log",
//     key: "application-central",
//     name: CentralLog_,
//   },
//   {
//     path: "/application-logs/system-error-log",
//     key: "application-system",
//     name: SystemErrorLog_,
//   },
//   {
//     path: "/sources",
//     key: "sources",
//     name: Sources_,
//   },
//   {
//     path: "/sources/add",
//     key: "sources-add",
//     name: AddSource_,
//   },
//   {
//     path: "/sources/view/:id",
//     key: "sources-view",
//     name: AddSource_,
//   },
//   {
//     path: "/sources/edit/:id",
//     key: "sources-edit",
//     name: AddSource_,
//   },
// ];

// export const staffRoutersList = [
//   {
//     path: "/",
//     key: "dashboard",
//     name: Dashboard_,
//   },
//   {
//     path: "/dashboard",
//     key: "dashboard",
//     name: Dashboard_,
//   },
//   {
//     path: "/scan-deliveries",
//     key: "scan-deliveries",
//     name: ScanDeliveries_,
//   },
//   {
//     path: "/entities/client",
//     key: "entities-client",
//     name: Client_,
//   },
//   {
//     path: "/entities/client/client-address/:id",
//     key: "entities-client-address",
//     name: ClientAddress_,
//   },
//   {
//     path: "/entities/client/details/:id",
//     key: "add-new-user",
//     name: AddClientDetails_,
//   },
//   {
//     path: "/entities/client/add-prep-center/:id",
//     key: "add-prep-center",
//     name: AddClientPrepCenter_,
//   },
//   {
//     path: "/entities/client/edit-prep-center/:id",
//     key: "edit-prep-center",
//     name: AddClientPrepCenter_,
//   },
//   {
//     path: "/entities/client/view-prep-center/:id",
//     key: "view-prep-center",
//     name: AddClientPrepCenter_,
//   },
//   {
//     path: "/inventory-order",
//     key: "inventory-order",
//     name: InventoryOrder_,
//   },
//   {
//     path: "/inventory-order/view",
//     key: "inventory-view",
//     name: InventoryOrderView_,
//   },
//   {
//     path: "/inventory-items",
//     key: "items",
//     name: Items_,
//   },
//   {
//     path: "/inventory-items/view",
//     key: "items-view",
//     name: ItemsView_,
//   },
//   {
//     path: "/inventory-items/sent-to-fba/:id",
//     key: "sent-to-fba",
//     name: SendToFBA_,
//   },
//   {
//     path: "/inventory-items/status/view/:id",
//     key: "items-status-view",
//     name: ItemStatusView_,
//   },
//   {
//     path: "/inventory-items/status/add",
//     key: "items-status-add",
//     name: ItemStatusAdd_,
//   },
//   {
//     path: "/receive-items",
//     key: "receive-items",
//     name: ReceiveItems_,
//   },
//   {
//     path: "/operation/shipping",
//     key: "operation-shipping",
//     name: Shipping_,
//   },
//   {
//     path: "/operation/prep-batch",
//     key: "operation-prep-batch",
//     name: PrepBatch_,
//   },
//   {
//     path: "/operation/prep-batch/add",
//     key: "operation-prep-batch-add",
//     name: AddPrepBatch_,
//   },
//   {
//     path: "/operation/prep-batch/edit/:id",
//     key: "operation-prep-batch-edit",
//     name: AddPrepBatch_,
//   },
//   {
//     path: "/operation/prep-batch/view/:id",
//     key: "operation-prep-batch-view",
//     name: ViewPrepBatch_,
//   },
//   {
//     path: "/operation/prep-batch/add-batch-items/:id",
//     key: "operation-prep-batch-add-items",
//     name: AddPrepBatchItems_,
//   },
//   {
//     path: "/operation/prep-batch/add-batch-items-shipment",
//     key: "operation-prep-batch-add-items-shipment",
//     name: AddPrepBatchItemsShipment_,
//   },
//   {
//     path: "/settings/profile",
//     key: "settings-profile",
//     name: Profile_,
//   },
//   {
//     path: "/settings/billing",
//     key: "settings-billing",
//     name: Billing_,
//   },
//   {
//     path: "/settings/marketplace-credentials",
//     key: "settings-marketplace-credentials",
//     name: MarketplaceCredentials_,
//   },
//   {
//     path: "/settings/help",
//     key: "settings-help",
//     name: Help_,
//   },
//   {
//     path: "/application-logs/central-log",
//     key: "application-central",
//     name: CentralLog_,
//   },
//   {
//     path: "/application-logs/system-error-log",
//     key: "application-system",
//     name: SystemErrorLog_,
//   },
//   {
//     path: "/admin-actions/pre-receiving-listings",
//     key: "admin-actions-pre-receiving-listings",
//     name: PreReceivingListings_,
//   },
//   {
//     path: "/admin-actions/cron-manager",
//     key: "admin-actions-cron-manager",
//     name: CronManager_,
//   },
//   {
//     path: "/admin-actions/cron-manager/add",
//     key: "admin-actions-cron-manager-add",
//     name: AddCronManager_,
//   },
//   {
//     path: "/admin-actions/cron-manager/edit/:id",
//     key: "admin-actions-cron-manager-edit",
//     name: AddCronManager_,
//   },
//   {
//     path: "/add-item-to-location",
//     key: "add-item-to-location",
//     name: AddItemToLocation_,
//   },
//   {
//     path: "/sources",
//     key: "sources",
//     name: Sources_,
//   },
//   {
//     path: "/sources/add",
//     key: "sources-add",
//     name: AddSource_,
//   },
//   {
//     path: "/sources/view/:id",
//     key: "sources-view",
//     name: AddSource_,
//   },
//   {
//     path: "/sources/edit/:id",
//     key: "sources-edit",
//     name: AddSource_,
//   },
//   {
//     path: "/fba-packing-summary-report",
//     key: "fba-packing-summary-report",
//     name: FbaPackingSummaryReport_,
//   },
//   {
//     path: "/operation/prep-center",
//     key: "operation-prep-center",
//     name: PrepCenter_,
//   },
//   {
//     path: "/operation/prep-center/add",
//     key: "operation-prep-center-add",
//     name: AddPrepCenter_,
//   },
//   {
//     path: "/operation/prep-center/edit/:id",
//     key: "operation-prep-center-edit",
//     name: AddPrepCenter_,
//   },
// ];
