export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "CREATE_NEW_INBOUND_SHIPMENT_PLAN_SUCCESS":
    case "CREATE_NEW_INBOUND_SHIPMENT_PLAN_ERROR":
      return {
        ...state,
        CreateNewInboundShiomentPlanResponse: action.updatePayload,
      };
    case "CHECK_INBOUND_SHIPMENT_OPERATION_STATUS_SUCCESS":
    case "CHECK_INBOUND_SHIPMENT_OPERATION_STATUS_ERROR":
      return {
        ...state,
        CheckInboundShipmentOperationStatusResponse: action.updatePayload,
      };
    case "CREATE_PACKING_OPTIONS_AND_GROUPS_SUCCESS":
    case "CREATE_PACKING_OPTIONS_AND_GROUPS_ERROR":
      return {
        ...state,
        CreatePackingOptionsAndGroupsResponse: action.updatePayload,
      };
    case "GET_PACKING_OPTIONS_AND_GROUPS_SUCCESS":
    case "GET_PACKING_OPTIONS_AND_GROUPS_ERROR":
      return {
        ...state,
        GetPackingOptionsAndGroupsResponse: action.updatePayload,
      };
    case "CONFIRM_PACKING_OPTION_SUCCESS":
    case "CONFIRM_PACKING_OPTION_ERROR":
      return {
        ...state,
        ConfirmPackingOptionResponse: action.updatePayload,
      };
    case "GET_PACKING_GROUP_ITEMS_SUCCESS":
    case "GET_PACKING_GROUP_ITEMS_ERROR":
      return {
        ...state,
        GetPackingGroupItemsResponse: action.updatePayload,
      };
    case "ADD_BOX_PACKING_DETAILS_SUCCESS":
    case "ADD_BOX_PACKING_DETAILS_ERROR":
      return {
        ...state,
        AddBoxPackingDetailsResponse: action.updatePayload,
      };
    case "GET_BOX_PACKING_LIST_SUCCESS":
    case "GET_BOX_PACKING_LIST_ERROR":
      return {
        ...state,
        GetBoxPackingListResponse: action.updatePayload,
      };
    case "EDIT_BOX_PACKING_DETAILS_SUCCESS":
    case "EDIT_BOX_PACKING_DETAILS_ERROR":
      return {
        ...state,
        EditBoxPackingDetailsResponse: action.updatePayload,
      };
    case "GET_BOX_PACKING_ITEMS_SUCCESS":
    case "GET_BOX_PACKING_ITEMS_ERROR":
      return {
        ...state,
        GetBoxPackingItemsResponse: action.updatePayload,
      };
    case "REMOVE_PACKAGE_ITEM_SUCCESS":
    case "REMOVE_PACKAGE_ITEM_ERROR":
      return {
        ...state,
        RemovePacakgeItemResponse: action.updatePayload,
      };
    case "DELETE_PACKAGE_SUCCESS":
    case "DELETE_PACKAGE_ERROR":
      return {
        ...state,
        DeletePackageResponse: action.updatePayload,
      };
    case "SUBMIT_PACKAGE_LEVEL_CONTENTS_SUCCESS":
    case "SUBMIT_PACKAGE_LEVEL_CONTENTS_ERROR":
      return {
        ...state,
        SubmitPackageLevelContentsResponse: action.updatePayload,
      };
    case "GET_PLACEMENT_OPTIONS_SUCCESS":
    case "GET_PLACEMENT_OPTIONS_ERROR":
      return {
        ...state,
        GetPlacementOptionsResponse: action.updatePayload,
      };
    case "GENERATE_PLACEMENT_OPTION_SUCCESS":
    case "GENERATE_PLACEMENT_OPTION_ERROR":
      return {
        ...state,
        GeneratePlacementOptionResponse: action.updatePayload,
      };
    case "GENERATE_TRANSPORTATION_OPTIONS_SUCCESS":
    case "GENERATE_TRANSPORTATION_OPTIONS_ERROR":
      return {
        ...state,
        GenerateTransportationOptionsResponse: action.updatePayload,
      };
    case "GET_TRANSPORTATION_OPTIONS_SUCCESS":
    case "GET_TRANSPORTATION_OPTIONS_ERROR":
      return {
        ...state,
        GetTransportationOptionsResponse: action.updatePayload,
      };
    case "GET_SHIPMENT_DETAILS_SUCCESS":
    case "GET_SHIPMENT_DETAILS_ERROR":
      return {
        ...state,
        GetShipmentDetailsResponse: action.updatePayload,
      };
    case "GET_SHIPMENT_BOXES_DETAILS_SUCCESS":
    case "GET_SHIPMENT_BOXES_DETAILS_ERROR":
      return {
        ...state,
        GetShipmentBoxesDetailsResponse: action.updatePayload,
      };
    case "CANCEL_INBOUND_PLAN_SUCCESS":
    case "CANCEL_INBOUND_PLAN_ERROR":
      return {
        ...state,
        CancelInboundPlanResponse: action.updatePayload,
      };
    case "CONFIRM_PLACEMENT_OPTION_SUCCESS":
    case "CONFIRM_PLACEMENT_OPTION_ERROR":
      return {
        ...state,
        ConfirmPlacementOptionResponse: action.updatePayload,
      };
    case "CONFIRM_TRANSPORTATION_SUCCESS":
    case "CONFIRM_TRANSPORTATION_ERROR":
      return {
        ...state,
        ConfirmTransportationResponse: action.updatePayload,
      };
    case "PRINT_BOX_LABELS_SUCCESS":
    case "PRINT_BOX_LABELS_ERROR":
      return {
        ...state,
        PrintBoxLabelsResponse: action.updatePayload,
      };
    case "CONFIRM_SHIPMENT_BOX_DETAILS_SUCCESS":
    case "CONFIRM_SHIPMENT_BOX_DETAILS_ERROR":
      return {
        ...state,
        ConfirmShipmentBoxDetailsResponse: action.updatePayload,
      };
    case "GET_BOXES_TRACKING_DETAILS_SUCCESS":
    case "GET_BOXES_TRACKING_DETAILS_ERROR":
      return {
        ...state,
        GetBoxesTrackingDetailsResponse: action.updatePayload,
      };
    case "SAVE_SHIP_SHIPMENT_SUCCESS":
    case "SAVE_SHIP_SHIPMENT_ERROR":
      return {
        ...state,
        SaveShipShipmentResponse: action.updatePayload,
      };
    case "SET_PREP_OWNER_SUCCESS":
    case "SET_PREP_OWNER_ERROR":
      return {
        ...state,
        SetPrepOwnerResponse: action.updatePayload,
      };
    case "GET_INBOUND_SHIPMENT_CONTENT_PER_BOX_SUCCESS":
    case "GET_INBOUND_SHIPMENT_CONTENT_PER_BOX_ERROR":
      return {
        ...state,
        GetInboundShipmentContentPerBoxResponse: action.updatePayload,
      };
    case "GET_BATCH_ALL_ITEMS_LIST_SUCCESS":
    case "GET_BATCH_ALL_ITEMS_LIST_ERROR":
      return {
        ...state,
        GetBatchAllItemsListResponse: action.updatePayload,
      };
    case "FAKE_ACTION_NEW_SHIPMENT":
      return [];
    default:
      return state;
  }
};
